// Custom css for the Horizontal
.h-timeline--container {
    position: relative;

    .h-timeline-1, .h-timeline-2 {

        p {
            width: 50%;
            margin-left: auto;
            margin-right: auto;

            @media screen and (max-width: 991.5px) {
                width: 100%;
            }
        }

        .slick-slide {
            text-align: center;
            position: relative;
            outline: none;
        }

        .slick-dot {
            width: 15px;
            height: 15px;
            display: inline-block;
            border-radius: 50%;
            background: #fff;
            border: 1px solid $gray-lighter;
            transition: backround-image 200ms ease-in-out;
        }
	}
	
	.h-timeline-2 p {
		text-align: left;
	}

    .left-arrow, .right-arrow {
        background-color: white;
        position: absolute;
        z-index: 1;
        top: -3px;
        height: 77px;

        button {
            outline: none;
        }
    }

    .slick-prev {
        left: 0;

        button {
            margin-right: 15px;
        }
    }

    .slick-next {
        right: 0;

        button {
            margin-left: 15px;
        }
    }

    .slick-prev, .slick-next {
        background: white;
        border: 2px solid $gray-lighter;

        // color: $brand-primary;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 0;
        font-weight: bold;
        top: 12px;
        z-index: 10;

        &:hover, &:focus {
            outline: none;
            background: white;
            color: $brand-primary;
        }

        &:before {
            color: $brand-primary;
        }
    }

    .slick-current {
        .title {
            font-weight: 400;
            line-height: 1.3;
            font-size: 23px;
            margin-bottom: 15px;
        }

        .slick-dot {
            background: $brand-primary;
        }
    }
}

.h-timeline-1 .slick-list.draggable {

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		display: block;
		height: 3px;
		background: $gray-lighter;
		top: 9px;
		left: 0;
		z-index: -1;

	}
}
.header {
	position: fixed;
	width: 100%;
	top: 0;
	background: $brand-primary;
	z-index: 11;

	.main-menu,
	.top-menu ul {
		transition: padding 0.2s ease-in;
	}

	.logo img {
		transition: max-width 0.2s ease-in;
	}

	&.scrolled {
		box-shadow: 0 -2px 10px $gray-light;

		.main-menu,
		.top-menu > ul {
			padding-top: vr(1) / 2;
		}

		.logo img {

			@include mappy-bp(large) {
				max-width: 100px;
			}
		}
	}

	.logo-wrapper {
		overflow: hidden;
	}

	.gradient-holder {
		@include ltr {
			background: -moz-linear-gradient(45deg, rgba(84,111,136,1) 0%, rgba(30,47,68,1) 57%, rgba(30,47,68,1) 100%);
			background: -webkit-linear-gradient(45deg, rgba(84,111,136,1) 0%,rgba(30,47,68,1) 57%,rgba(30,47,68,1) 100%);
			background: linear-gradient(45deg, rgba(84,111,136,1) 0%,rgba(30,47,68,1) 57%,rgba(30,47,68,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#546f88', endColorstr='#1e2f44',GradientType=1 );
		}

		@include rtl {
			background: rgb(30,47,68);
			background: -moz-linear-gradient(-45deg, rgba(30,47,68,1) 0%, rgba(30,47,68,1) 43%, rgba(84,111,136,1) 100%);
			background: -webkit-linear-gradient(-45deg, rgba(30,47,68,1) 0%,rgba(30,47,68,1) 43%,rgba(84,111,136,1) 100%);
			background: linear-gradient(135deg, rgba(30,47,68,1) 0%,rgba(30,47,68,1) 43%,rgba(84,111,136,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e2f44', endColorstr='#546f88',GradientType=1 );
		}
	}

	.logo {
		position: relative;

		.gap-filler {
			content: '';
			display: block;
			position: absolute;
			@include left(1px);
			top: 0;
			bottom: 0;
			width: 5000px;
			background: #fff;

			@include ltr {
				transform: translateX(-100%);
			}

			@include rtl {
				transform: translateX(100%);
			}
		}

		.logo-inner-wrapper {
			display: inline-block;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				@include right(0);
				top: 0;
				bottom: 0;
				width: 145px;
				background: url('../img/header-lines.png') center;
				opacity: 0.25;


				@include ltr {
					transform: translateX(85%);
				}

				@include rtl {
					transform: translateX(-85%);
				}
			}
		}

		a {
			display: inline-block;
		}

		img {
			max-width: 80px;

			@include mappy-bp(large) {
				max-width: 120px;
			}
		}
	}
}

.section {
	float: left;
	width: 100%;

	&--lg {
		padding: vr(2) 0;

		@include mappy-bp(small) {
			padding: vr(3) 0;
		}
	}

	&--md {
		padding: vr(1) 0;

		@include mappy-bp(small) {
			padding: vr(2) 0;
		}
	}

	&--sm {
		padding: vr(1)/2 0;

		@include mappy-bp(medium) {
			padding: vr(1) 0;
		}
	}

	&--full-height {
		height: 100vh;
	}

	&--divider {
		padding: vr(1) 0;
	}

	&-anchor { // Header's height
		@include mappy-bp(small) {
			margin-top: -100px;
			padding-top: 100px;
		}

		margin-top: -70px;
		padding-top: 70px;
	}
}

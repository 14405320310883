.slick-slider {
    
    &.arrows--lg {
        
        .slick-arrow {
            width: 40px;
            height: 40px;

            &:before {
                font-size: 40px;
            }
        }

        .slick-next {
            @include right(-45px);
            @include left(auto);
        }

        .slick-prev {
            @include left(-45px);
            @include right(auto);
        }
    }

    &.arrows--dark {
        .slick-arrow {

            &:before {
                color: $brand-primary;
            }
        }
    }

    &.equal-height {

        .slick-track {
            display: flex;

            .slick-slide {
                display: flex;
                height: auto;
                justify-content: center;

                > div {
                    display: flex;
                }
            }
        }
    }
}
.document-card {
    position: relative;
    padding: rem-calc(14);

    @include mappy-bp(max medium) {
        padding-bottom: 80px;
    }


    &__title {
        text-align: center;
        transition: transform 350ms ease-in-out;
        margin-top: vr(1) / 2;
        margin-bottom: 0;

        // @include mappy-bp(max small) {
        //     transform: translateY(-20px) scale(0.8);
        // }
    }

    &__actions {
        transition: transform 350ms ease-in-out, opacity 350ms ease-in-out, visibility 350ms ease-in-out;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        border-top: 1px solid $gray-lighter;
        box-shadow: 0px -2px 10px 1px rgba(240, 240, 240, 1);
        padding-top: vr(1) / 4;
        padding-bottom: vr(1) / 4;
        background: #fff;

        @include mappy-bp(medium) {
            opacity: 0;
            visibility: hidden;
        }


        ul {
            @include horizontal-menu($direction: center);

            padding: 0;

            li {
                margin: rem-calc(0 7 0);

                a {
                    position: relative;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

                &.download {
                    a {
                        border: 1px solid $brand-primary;

                        &:before {
                            background-image: url("../img/icons/download-primary.svg");
                        }
                    }
                }

                &.preview {
                    a {
                        border: 1px solid $brand-secondary;
                        background: $brand-secondary;

                        &:before {
                            background-image: url("../img/icons/arrow-white.svg");
                        }
                    }
                }
            }
        }
    }

    &:hover {
        @include mappy-bp(medium) {
            .document-card {
                &__title {
                    transform: translateY(-20px) scale(0.8);
                }

                &__actions {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.sidr {
	display: block;
	position: fixed;
	top: 0;
	height: 100%;
	z-index: 999999;
	width: 230px;
	overflow-x: hidden;
	overflow-y: auto;

	@include border-left(1px solid $gray-lighter);

	&.right {
		left: auto;
		right: -260px
	}

	&.left {
		left: -260px;
		right: auto
	}

	li {

		&.not-mobile {
			display: none;
		}
	}

	.sub-menu {
		@include clean-menu;
		padding: 0;
	}
}

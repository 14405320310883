.breadcrumbs {
	@include horizontal-menu($separator: true);
	text-transform: uppercase;
	padding: 0;

	li {
		font-family: $headings-font-family;
		font-size: ms(-1);

		@include mappy-bp(small) {
			@include typi('h6');
		}
	}
}

@import '../vendors/typiConfig';

// Scuba Offc
@font-face {
    font-family: 'Scuba Offc';
    src: url('../fonts/scuba/subset-ScubaOffc-MediumItalic.woff2') format('woff2'),
        url('../fonts/scuba/subset-ScubaOffc-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Scuba Offc';
    src: url('../fonts/scuba/subset-ScubaOffc-Medium.woff2') format('woff2'),
        url('../fonts/scuba/subset-ScubaOffc-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Scuba Offc';
    src: url('../fonts/scuba/subset-ScubaOffc-Light.woff2') format('woff2'),
        url('../fonts/scuba/subset-ScubaOffc-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Scuba Offc';
    src: url('../fonts/scuba/subset-ScubaOffc-LightItalic.woff2') format('woff2'),
        url('../fonts/scuba/subset-ScubaOffc-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: 'Trola Text';
    src: url('../fonts/trola/TrolaText-LightItalic.woff2') format('woff2'),
        url('../fonts/trola/TrolaText-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Trola Text';
    src: url('../fonts/trola/TrolaText-Heavy.woff2') format('woff2'),
        url('../fonts/trola/TrolaText-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Trola Text';
    src: url('../fonts/trola/TrolaText-Regular.woff2') format('woff2'),
        url('../fonts/trola/TrolaText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Trola Text';
    src: url('../fonts/trola/TrolaText-Light.woff2') format('woff2'),
        url('../fonts/trola/TrolaText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Trola Text';
    src: url('../fonts/trola/TrolaText-Bold.woff2') format('woff2'),
        url('../fonts/trola/TrolaText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


body {
	font-size: rem-calc(16);
	line-height: inherit;
	font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	margin-top: 0;
	margin-bottom: vr(1);

	&.dark,
	.dark & {
		color: #fff;
	}

	.meta {
		display: inline-block;
		padding: 0 $padding-xs-horizontal;
		color: mix(#fff, $gray, 50%);
		font-size: 0.8em;
	}
}

h1,
h2,
h3 {
	color: $brand-primary;

	&.sharted {
		@include padding-left(35px);
	}
}

h4,
h5,
h6 {

	&.sharted {
		@include padding-left(30px);
	}
}

h1 {
	@include typi('h1');
}

h2 {
	@include typi('h2');
}

h3 {
	@include typi('h3');
}

h4 {
	@include typi('h4');
}

h5 {
	@include typi('h5');
}

h6 {
	@include typi('h6');
	color: $brand-secondary;
}

h1.large {
	@include typi('h1Large');

	text-transform: uppercase;
	font-weight: 600;
}


p {
	margin-top: 0;

	&.dark,
	.dark & {
		color: #fff;
	}

	&.text-large,
	.text-large & {
		@include typi('h6');
	}

	&.lead {
		color: $brand-secondary;
		@include typi('h6');
	}

	&.label {
		color: $brand-secondary;
	}

	&.bullet {

		span {
			display: inline-block;
			padding: $padding-xs-vertical $padding-base-horizontal;
			border-radius: $border-radius-base;
		}

		&--primary span {
			background: $brand-primary;
		}

		&--secondary span {
			background: $brand-secondary;
		}

		&--sm span {
			padding: $padding-xs-vertical $padding-small-horizontal;
		}
	}

	+ p,
	+ ul,
	+ ol {
		margin-top: vr(1);
	}
}

a {
	.dark & {
		color: #fff;

		&:hover,
		&:focus {
			color: #fff;
		}
	}

	&.text-large,
	.text-large & {
		font-size: ms(1);
		font-weight: normal;
	}
}

ul,
ol,
table {
	&.dark,
	.dark & {
		color: #fff;
	}
}

ul.custom {
    @include clean-menu;

    padding: 0;

    li {
        vertical-align: middle;

        @include mappy-bp(max xsmall) {
            margin-bottom: vr(1) / 4;
        }


        a, span {
            @include padding-left(rem-calc(17));

            position: relative;
            display: inline-block;

            &:before {
                @include left(0);

                content: '';
                position: absolute;
                top: 7px;
                display: inline-block;
                height: 14px;
                width: 14px;
                margin-right: rem-calc(7);
                background-image: url("../img/icons/slash-color.svg");
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}

.sharted {
	@include sharta();

	&.dark,
	.dark & {
		@include sharta($color: white, $padding: false);
	}
}

.text-right {
	@include text-align(right);
}

.text-left {
	@include text-align(left);
}

.text-center {
	text-align: center;
}

.main-content {
	p,
	ul,
	ol {
		margin-bottom: vr(1);
	}
}

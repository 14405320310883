.hero-image {
	position: relative;

	@include mappy-bp(small) {
		padding: vr(5) 0;
	}

	@include mappy-bp(medium) {
		padding: vr(7) 0;
	}

	&--small {
		@include mappy-bp(small) {
			padding: vr(3) 0;
		}

		@include mappy-bp(medium) {
			padding: vr(4) 0;
		}
	}

	&--centered {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&--full-height {
		height: calc( 100vh - (87px + 73px);

		@include mappy-bp(small) {
			height: calc( 100vh - (112px + 73px);
		}
	}

	&__content {

		@include mappy-bp(small) {
			max-width: 70%;
		}

		@include mappy-bp(medium) {
			max-width: 50%;
		}
	}
}

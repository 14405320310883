.atable {
	width: 100%;

	tr {

		&.special-1 {

			td {
				font-weight: 600;
				background-color: $gray;
			}
		}

		&.special-2 {

			td {
				font-weight: 600;
				background-color: $gray;
			}
		}
	}

	&--one {
		border-collapse: separate;
		border-spacing: 0 rem-calc($air-xsmall);

		tr {

		}

		td {
			padding: rem-calc($air-xsmall);
			background: #fff;
			vertical-align: top;

			&.title {
				// font-size: rem-calc($font-size-base);
				font-weight: 600;
				color: $text-color;

				width: 50%;
			}
		}
	}

	&--two {

		tr {
			&:hover {

				td {
					background-color: $gray-lighter;
				}
			}
		}

		th, td {
			padding: rem-calc($air-xsmall);

			@media screen and (min-width: 993px) {
				@include text-align(right);

				&.left {
					@include text-align(left);
				}

				&:first-child{
					@include text-align(left);
				}
			}
		}

		th {
			color: $brand-primary;
			border-bottom: 2px solid $brand-primary;

			&.large {
				width: 40%;
			}

			&.small {
				width: 20%;
				@include text-align(right);
			}
		}

		td {
			border-bottom: 1px solid $gray-lighter;
		}
	}

	&--three {

		th, td {
			padding: rem-calc($air-xsmall);

			&:last-child {

				@media screen and (min-width: 993px) {
					@include text-align(right);
				}
			}
		}

		th {
			color: $brand-primary;
			border-bottom: 2px solid $brand-primary;
			@include text-align(left);

			&.large {
				width: 40%;
			}

			&.small {
				width: 20%;
			}
		}

		tr {

			&:nth-child(even) {

				td {
					background-color: lighten($gray-lighter, 2%);
				}
			}
		}
	}

	&--capped {

		td  {

			&:first-child {

				a, span, p {

					@media screen and (min-width: 993px) {
						display: inline-block;
						max-width: 60%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 992px) {

	.rtable {
		width: 100%;

		thead {
			display: none;
		}

		tbody td {
			display: block;
			font-size: rem-calc($font-size-large);
		}

		tbody td:before {
		    content: attr(data-th);
		    font-size: rem-calc($font-size-small);
		    font-weight: 100;
		    display: block;
		}
		
		td:first-child {
			padding-top: rem-calc(5);
		}
		
		td:last-child {
			padding-bottom: rem-calc(5);
			border-bottom: 1px solid $brand-primary;
		}

	}
}

$sector-colors: (
	asset-management: #055A62,
	brokerage: #3E5564,
	investment-banking: #9D2432,
	market-making: #542650,
	research: #1B2B3A
);

@each $sector, $color in $sector-colors {
	.sector--#{$sector} {

		h1, h2, h3, h4, h5, h6, a:not(.btn) {
			color: $color;
		}
		
		//sidebar
		.sidebar_body {
			border-color: $color;
		}
		
		//panel
		.panel--primary-border-bottom {
			border-bottom: 12px solid $color;
		}

		//Timeline
		.slick-current .slick-dot{
			background-color: $color !important;
			border-color: $color !important;
		}

		.slick-current .title, .slick-prev:before, .slick-next:before{color: $color;}
            
	}

	.sector-bg--#{$sector} {
		background-color: $color;
	}

}

.bottom-link {
	position: relative;
	padding-bottom: vr(1) !important;

	&__anchor {
		position: absolute;
		@include right(0);
		bottom: vr(1) / 2;
	}

	&--lg {
		padding-bottom: 60px !important;

		.bottom-link__anchor {
			bottom: 20px;
		}
	}
}

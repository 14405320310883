.page-banner {
	position: relative;
	width: 100%;
	min-height: 200px;
	background-size: cover;

	@include mappy-bp(small) {
		min-height: 300px;
	}

	@include mappy-bp(medium) {
		min-height: 350px;
	}

	@include mappy-bp(large) {
		min-height: 450px;
	}

	@include mappy-bp(xlarge) {
		min-height: 550px;
	}

	&--sm {
		@include mappy-bp(small) {
			min-height: 200px;
		}
	
		@include mappy-bp(medium) {
			min-height: 250px;
		}
	
		@include mappy-bp(large) {
			min-height: 300px;
		}
	
		@include mappy-bp(xlarge) {
			min-height: 350px;
		}
	}

	&__content {
		position: absolute;
		width: 100%;
		z-index: 2;
		top: 75px;
	}

	&:after {
		content: '';
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0.5;
		z-index: 1;

		background-image: url('../img/backgrounds/sico-mask.svg');
		background-repeat: no-repeat;
		background-size: auto 200px;
		background-position: right center;

		@include mappy-bp(small) {
			background-size: auto 300px;
		}

		@include mappy-bp(medium) {
			background-size: auto 350px;
		}

		@include mappy-bp(large) {
			background-size: auto 450px;
		}

		@include mappy-bp(xlarge) {
			background-size: auto 550px;
		}
	}
}

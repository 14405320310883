.panel {
	border-radius: 0;
	background: $gray-lighter;
	padding: vr(1);
	border: none;

	&--light {
		background: #fff;
	}
}

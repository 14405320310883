//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
	display: inline-block;
	margin-bottom: 0; // For input.btn
	font-weight: $btn-font-weight;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	white-space: nowrap;
	@include button-size($padding-small-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, 0);
	@include user-select(none);

	&:active,
	&.active {
		&:focus,
		&.focus {
			@include tab-focus;
		}
	}

	&:hover,
	&:focus,
	&.focus {
		color: $btn-default-color;
		text-decoration: none;
	}

	&:active,
	&.active {
		outline: 0;
		background-image: none;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: $cursor-disabled;
		@include opacity(.65);
		@include box-shadow(none);
	}

	.glyphicon {
		display: inline-block;
		font-size: rem-calc(13);
		@include margin-left(rem-calc($padding-xs-horizontal));
	}

	.caret {
		@include margin-left(rem-calc(10));
	}

	// [converter] extracted a& to a.btn
}

a.btn {
	&.disabled,
	fieldset[disabled] & {
		pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
	}
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
	@include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}
.btn-primary {
	@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-secondary {
	@include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.btn-icon {
	@include padding-right(60px);
	position: relative;

	&:not(.btn-ghost) {
		@include padding-left(0);
	}

	&:before {
		@include right(0);
		border: 1px solid $brand-primary;
		position: absolute;
		width: 40px;
		line-height: 40px;
		top: 0;
		bottom: 0;

		content: '+';
	}

	.dark & {

		&:before {
			border-color: #fff;
		}
	}
}

.btn-ghost {
	border: 1px solid #fff;
	transition: background 200ms ease-in, color 200ms ease-in, border-color 200ms ease-in;

	&:hover {
		background: #fff;
		color: $brand-primary !important;
		border-color: $brand-primary;
	}

	&.btn-icon {

		&:before {
			border: 0;
			@include border-left(1px solid #fff);
		}
	}
}

.btn-primary {
	// @include padding-left(rem-calc(40));
	// @include margin-right(rem-calc(24));

	background: $brand-primary;
	color: #fff;

	&:before {
		left: 10px;
	}
}

// Success appears as green
.btn-success {
	@include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
// Info appears as blue-green
.btn-info {
	@include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
// Warning appears as orange
.btn-warning {
	@include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
	@include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
	color: $link-color;
	font-weight: normal;
	border-radius: 0;

	&,
	&:active,
	&.active,
	&[disabled],
	fieldset[disabled] & {
		background-color: transparent;
		@include box-shadow(none);
	}
	&,
	&:hover,
	&:focus,
	&:active {
		border-color: transparent;
	}
	&:hover,
	&:focus {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
		background-color: transparent;
	}
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus {
			color: $btn-link-disabled-color;
			text-decoration: none;
		}
	}
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
	// line-height: ensure even-numbered height of button next to large input
	@include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $btn-border-radius-large);
}
.btn-sm {
	// line-height: ensure proper height of button next to small input
	@include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}
.btn-xs {
	@include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}

// Block button
// --------------------------------------------------

.btn-block {
	display: block;
	width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
	margin-top: 5px;
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
	&.btn-block {
		width: 100%;
	}
}

.btn-download {
	position: relative;
	padding: 0;

	&:before {
		content: '';
		display: inline-block;
		width: 35px;
		height: 24px;
		@include margin-right(15px);
		background: url('../img/icons/download.svg') no-repeat center;
		background-size: contain;
	}

	&.dark,
	.dark & {
		&:before {
			background: url('../img/icons/download-white.svg') no-repeat center;
		}
	}
}

// Drowdowns
.btn-group {

	&.open {
		.dropdown-toggle {
			box-shadow: none;
		}
	}

	.dropdown-menu {
		left: 0;
		right: 0;

		li {

			a {
				@include typi('xsmall');
				padding: 5px 10px;
				font-weight: normal;
			}
		}
	}
}

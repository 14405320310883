// Text alignment
.text-center {
	text-align: center;
}

.text-right {
	@include text-align(right);
}

.text-left {
	@include text-align(left);
}

// Fonts
.font-sans-serif {
	font-family: $font-family-sans-serif;
}

.font-serif {
	font-family: $font-family-serif;
}

.fz-20 {
	font-size: 20px;
}

// Background
.bg-primary {
	background-color: $brand-primary;
}

.bg-gray-lighter {
	background-color: $gray-lighter
}

.bg-sico-live {
	background-image:url('./img/sico-live-bg.png');
	background-color: #809abe;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.bg-sustainability-live {
    background-image: url('../img/sustnabiltyBackGround.jpg');
    background-color: #76ac60;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center
}

.shade-box {
	padding: 15px;
	background-color: rgba(255,255,255,0.9);
}
// Text Color
.color-primary {
	color: $brand-primary;
}

.color-white {
	color: white;
}


// Positioning
.fill-parent {
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;

	.no-js & {
		position: relative;
	}
}

.full-height {
	height: 100%;
}

// Vertical Alignment - Ghost Element
.v-center-parent {
	display: flex;
}

.v-center-child {
	display: inline-block;
	align-self: center;
}

// Columns
.mc-2 {

	@include mappy-bp(xsmall) {
		column-count: 2;
	}
}

// Margins
.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: vr(1);

	&--sm {
		@include mappy-bp(max small) {
			margin-bottom: vr(1) !important;
		}
	}

	&--md {
		@include mappy-bp(max medium) {
			margin-bottom: vr(1) !important;
		}
	}
}

.mb-2 {
	margin-bottom: vr(2);

	&--sm {
		@include mappy-bp(max small) {
			margin-bottom: vr(2) !important;
		}
	}

	&--md {
		@include mappy-bp(max medium) {
			margin-bottom: vr(2) !important;
		}
	}
}

.pb-0 {
	padding-bottom: 0;
}

.py-15 {
	padding-top: 15px;
	padding-bottom: 15px;
}
.mb-30 {
	margin-bottom: 30px;
}
.p-relative {
	position: relative;
}

// Play Overlay
.icon-overlay {
	position: relative;

	&__icon {
		height: 72px;
		width: 72px;
		left: 50%;
		top: 50%;
		margin-left: -36px;
		margin-top: -36px;
		position: absolute;
		cursor: pointer;

		&--play {
			background: url("../img/icons/play.png") no-repeat;
		}
	}
}

// Fighting the FOUC
.no-js .no-fouc {
	display: none;
}

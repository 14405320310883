.sidebar {
	@include typi('small');
	border: 1px solid $gray-light;

	&_body {
		padding: rem-calc(15);
		border-top: 12px solid $brand-primary;

		span {
			display: inline-block;
			margin-bottom: vr(1) / 4;
		}

		ul {
			margin: 0 0 30px 0;
			list-style: none;
			@include padding-left(15px);

			li {
				margin-bottom: vr(1) / 4;
			}
		}
	}
}
.footer {
	background-image: url('../img/backgrounds/footer-pattern-white.svg');
	background-repeat: no-repeat;
	background-position: 100px bottom;

	@include mappy-bp(small) {
		background-position: 340px bottom;
	}

	@include mappy-bp(large) {
		background-position: 600px bottom;
	}

	.copyrights {
		font-size: $font-size-small;
		text-align: center;
		margin-bottom: 0;
	}

	a {
		@include typi('small');

		&:hover {
			text-decoration: underline;
		}
	}
}

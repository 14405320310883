.required-style {
    color: red;
}


#successMsg {
    margin: 15px 0px;
}

.alert-success {
    background-color: #d4edda;
    padding: 10px;
    border-radius: 10px;
    color: #155724;
    margin: 10px 0px;
    width: fit-content;
}


.alert-danger {
    background-color: #f8d7da;
    padding: 10px;
    border-radius: 10px;
    color: #721c24;
    margin: 10px 0px;
    width: fit-content;
}
.main-call-to-actions {
	margin-top: -7px;
	width: 100%;
	padding: vr(2) 0 0;

	background: rgb(238,238,238);
	background: -moz-linear-gradient(top, rgba(238,238,238,1) 0%, rgba(204,204,204,1) 100%);
	background: -webkit-linear-gradient(top, rgba(238,238,238,1) 0%,rgba(204,204,204,1) 100%);
	background: linear-gradient(to bottom, rgba(238,238,238,1) 0%,rgba(204,204,204,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=0 );

	.image-box {

		&__image {
			border-color: $brand-primary;

			@include mappy-bp(max xlarge) {
				padding-bottom: vr(1) / 2;
				margin-bottom: vr(1) / 2;
				
				img {
					max-width: 45px;
				}
				
			}
		}

		&__title {
			color: $brand-primary;
		}
	}

	@include mappy-bp(small) {
		padding: vr(1) 0 0;
		position: absolute;
		bottom: 7px;

		background: rgba(#fff, 0.50);
		filter: none;
	}

	@include mappy-bp(medium) {
		padding: vr(1) 0;
	}
}

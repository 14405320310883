@mixin clean-menu() {
	list-style: none;
	margin: 0;
}

@mixin horizontal-menu($direction: left, $gutter: 15px, $separator: false) {
	@include clean-menu();
	@include text-align($direction);

	> li {
		display: inline-block;
		vertical-align: middle;

		@if ($direction == 'left') {
			@include margin-right($gutter);
		} @else {
			@include margin-left($gutter);
		}

		@if ($separator) {
			&:after {
				content: '/';
				display: inline-block;
				@include padding-left($gutter);
			}

			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}

@mixin drop-down($top-gutter: 0) {
	li {
		position: relative;

		.sub-menu {
			@include clean-menu;
			@include left(0);
			position: absolute;
			white-space: nowrap;
			transition: all 0.1s linear;

			opacity: 0;
			visibility: hidden;
			margin-top: 5px;

			z-index: 10;

			li {
				display: block;
				@include text-align(left);

				a {
					display: inline-block;
					padding: rem-calc(5 15);
					width: 100%;
				}
			}
		}

		&:last-child,
		&.menu-edge {
			.sub-menu {
				@include left(auto);
				@include right(0);

				li {
					@include text-align(right);

					&.has-sub-menu {
						> a {
							@include padding-right(rem-calc(5) !important);
							@include padding-left(rem-calc(23)  !important);
							background: url('../img/caret-8-primary-left.png') no-repeat  !important;

							@include ltr {
								background-position: 3% center !important;
							}

							@include rtl {
								background-position: 97% center !important;
							}
						}
					}
				}


				ul {
					@include left(auto !important);
					@include right(100%);
				}
			}
		}

		&:hover {
			.sub-menu {
				opacity: 1;
				visibility: visible;
				margin-top: 3px;
			}
		}
	}

	> li {

		&.has-sub-menu {

			> a {
				@include padding-right(rem-calc(23));
				display: inline-block;
				background: url('../img/caret-16-white.png') no-repeat;

				@include ltr {
					background-position: right center;
				}

				@include rtl {
					background-position: left center;
				}
			}

			ul {
				min-width: 150px;
				background: #fff;
				padding: 0;
				box-shadow: 1px 0 10px rgba(#000, 0.3);

				&.sub-menu {
					@if ($top-gutter != 0) {
						top: rem-calc($top-gutter);
					}

					margin: 0;

					ul {
						position: absolute;
						top: 0;
						@include left(100%);

						transition: all 0.1s linear;

						opacity: 0;
						visibility: hidden;
					}

					li {
						position: relative;
						margin: 0;
						padding-left: rem-calc(16);
						padding-right: rem-calc(16);

						&:first-child {
							padding-top: rem-calc(8);
						}

						&:last-child {
							padding-bottom: rem-calc(8);
						}

						&.has-sub-menu {

							> a {
								@include padding-right(rem-calc(23));
								display: inline-block;
								width: 100%;
								background: url('../img/caret-8-primary-right.png') no-repeat;
								transition: background-position 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

								@include ltr {
									background-position: 97% center;
								}

								@include rtl {
									background-position: 3% center;
								}
							}
						}

						&:hover {

							> ul {
								opacity: 1;
								visibility: visible;
							}

							> a {
								color: $brand-secondary;
								
								@include ltr {
									background-position: right center;
								}

								@include rtl {
									background-position: left center;
								}
							}
						}
					}

					a {
						color: $brand-primary;
						padding: rem-calc(5);
					}
				}
			}
		}
	}
}

@mixin inline-menu() {
	@include padding-left(rem-calc(20));
}

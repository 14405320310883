.main-search {
	position: relative;

	.search-toggle {
		position: absolute;
		@include right(30px);
		top: 50%;
		transform: translateY(-50%);

		color: $gray-light;
		padding: 10px 15px;
		font-weight: bolder;
		font-size: ms(3);

		&:hover {
			color: $gray;
		}
	}

	input {
		font-size: ms(2);
		padding: vr(1) / 2 vr(1);
		height: 64px;
		border: 0;
		border-radius: 0;

		@include mappy-bp(small) {
			font-size: ms(3);
		}
	}
}

.search-overlay {
	position: absolute;
	top: 65px;
	right: 0;
	bottom: 0;
	left: 0;

	visibility: hidden;
	opacity: 0;

	z-index: 999;

	&.is-active {
		opacity: 1;
		visibility: visible;
	}
}

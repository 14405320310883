.split-section {

	table {
		margin-bottom: rem-calc(25);
		tr td {
			padding-bottom: rem-calc(5);
		}
	}

	&--colored {
		> div {
			padding: rem-calc(30);
		}
	}
	
	&--transparent {
		> div {
			padding: rem-calc(30);
			margin: rem-calc(50) 0;

			&:first-child {
				border-right: 1px solid $brand-primary;
			}
		}
	}

	@media screen and (max-width: 992px) {
		&--colored {
			
			td:first-child {
				padding-top: rem-calc(5);
			}
			
			td:last-child {
				padding-bottom: rem-calc(5);
				border-bottom: 1px solid white;
			}
		}
		
		&--transparent {
			> div {
				margin: 0;
				
				&:first-child {
					border-right: none;
				}
			}

			td:first-child {
				padding-top: rem-calc(5);
			}
			
			td:last-child {
				padding-bottom: rem-calc(5);
				border-bottom: 1px solid $brand-primary;
			}
		}
	}
}
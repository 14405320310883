.data-table {

	th {

		&:focus,
		&:active {
			outline: 0;
		}

		&.sorting,
		&.sorting_asc,
		&.sorting_desc {
			background-repeat: no-repeat;
			background-position: left;
			@include padding-left(25px);
		}

		&.sorting {
			background-image: url('../img/icons/sort-both.png');
		}

		&.sorting_asc {
			background-image: url('../img/icons/sort-asc.png');
		}

		&.sorting_desc {
			background-image: url('../img/icons/sort-desc.png');
		}
	}
}

.dataTables_info,
.dataTables_paginate {
	padding-top: rem-calc($air-small);
}

.dataTables_info {
	clear: both;
    @include float(left);
}

.dataTables_paginate {
    @include float(right);
    @include text-align(right);

    .paginate_button {
    	display: inline-block;
	    text-align: center;
	    cursor: pointer;
	    border: 1px solid transparent;
	    padding: rem-calc($air-xsmall / 2 ) rem-calc($air-xsmall);

	    &.current {
	    	border: 1px solid $brand-primary;
	    }
    }
}

.results-table {

	.dataTables_length {
		display: none;
	}

	#DataTables_Table_0_filter {
		margin-bottom: rem-calc($air-small);

		label,
		input {
			width: 100%;
		}

		input[type="search"] {
			border: 1px solid $gray-light;

			&:focus {
				outline: 0;
				box-shadow: 1px 1px 10px rgba($gray-light, 0.5);
			}
		}
	}
}

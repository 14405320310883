@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 10px !default;
$hamburger-layer-width: 30px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
		boring,
		collapse,
		collapse-r,
		elastic,
		elastic-r,
		emphatic,
		emphatic-r,
		squeeze
	)
	!default;

// Base Hamburger (We need this)
// ==================================================
@import 'base';

// Hamburger types
// ==================================================
@import 'types/3dx';
@import 'types/3dx-r';
@import 'types/3dy';
@import 'types/3dy-r';
@import 'types/3dxy';
@import 'types/3dxy-r';
@import 'types/arrow';
@import 'types/arrow-r';
@import 'types/arrowalt';
@import 'types/arrowalt-r';
@import 'types/arrowturn';
@import 'types/arrowturn-r';
@import 'types/boring';
@import 'types/collapse';
@import 'types/collapse-r';
@import 'types/elastic';
@import 'types/elastic-r';
@import 'types/emphatic';
@import 'types/emphatic-r';
@import 'types/minus';
@import 'types/slider';
@import 'types/slider-r';
@import 'types/spin';
@import 'types/spin-r';
@import 'types/spring';
@import 'types/spring-r';
@import 'types/stand';
@import 'types/stand-r';

@import 'types/vortex';
@import 'types/vortex-r';

// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }


.top-menu {

	ul {
		@include horizontal-menu($direction: right, $gutter: 20px);
		@include drop-down($top-gutter: 24);
		padding-top: vr(1) / 2;

		@include mappy-bp(large) {
			padding-top: vr(1);
		}

		> li {
			font-weight: 300;
			font-size: rem-calc(14);
			vertical-align: middle;

			@include mappy-bp(large) {
				padding-bottom: rem-calc(10);
			}

			a {
				display: inline-block;
			}


			&.search {

				a {
					width: 32px;
					height: 32px;
					background: url('../img/search-white.png');
				}
			}

			&:not(.not-mobile) {
				display: none;

				@include mappy-bp(medium) {
					display: inline-block;
				}
			}
		}
	}
}

.main-menu {
	padding-top: vr(1);
	font-family: $font-family-sans-serif;

	@include mappy-bp(max large) {
		display: none;
	}

	> ul {
		@include horizontal-menu($direction: right, $gutter: 20px);
		@include drop-down($top-gutter: 32);
		padding: 0;

		.current {
			border-bottom: 1px solid $brand-primary;
		}

		a {
			text-decoration: none;
		}

		> li {
			padding-bottom: vr(1);
			font-size: rem-calc(16);

			&.top-menu-item {
				@include mappy-bp(medium) {
					display: none;
				}
			}

			&:hover {
				cursor: pointer;

				> a {
					color: $brand-secondary;

				}
			}
		}
	}
}


.mobile-menu {
	@include text-align(right);
	@include mappy-bp(large) {
		display: none;
	}
}

// Mobile Menu
.mobile-menu-items {

	> ul {
		@include clean-menu();
		padding: 0;

		ul {

			a {
				@include padding-left(rem-calc(40));
				color: $gray;
			}
		}

		li {
			border-bottom: 1px solid $gray-lighter;

			&:last-child {
				border-bottom: 0;
			}
		}

		a {
			padding: rem-calc(10 20);
			display: inline-block;
			width: 100%;
			// color: $black;
			width: 100%;

			&:hover {
				color: $brand-secondary;
			}
		}
	}

	body:not(.sidr-open) &  ul {
		height: 0;
		opacity: 0;
		visibility: hidden;
	}
}

.menu-overlay {
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background: rgba($brand-primary, 0.6);
	z-index: 9999;
	opacity: 0;
	visibility: hidden;
	transition: all 250ms linear;

	.sidr-open & {
		opacity: 1;
		visibility: visible;
	}
}

.footer-menu {
	@include clean-menu;
	padding: 0;

	> li {

		> a {
			text-transform: uppercase;
			font-weight: 400;
		}
	}

	.sub-menu {
		@include clean-menu;
		padding: 0;
		font-size: $font-size-small;

		li {
			margin-bottom: rem-calc(3);
		}
	}
}

.main-slider-container {
	position: relative;
}

.hero-slider {

	.slide {
		position: relative;
		background: #dedede;

		@include mappy-bp(max small) {
			height: 60vh;
		}

		&__wrapper {
			background-position: center top;
		}

		&__heading {
			font-size: ms(3);
			font-weight: 300;
			color: #fff;

			padding: rem-calc(0 40);
			transform: translateY(-50%);

			@include mappy-bp(small) {
				color: $brand-primary;
				font-size: ms(5);
				padding: rem-calc(0 100);
			}

			@include mappy-bp(medium) {
				font-size: ms(7);
			}

			@include mappy-bp(large) {
				font-size: ms(9);
			}

			.no-js & {
				position: absolute;
				width: 100%;
				top: 25%;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.slick-next,
	.slick-prev {
		width: auto;
		height: auto;
		z-index: 1;
		top: 0;

		&:before {
			font-size: 20px;

			@include mappy-bp(small) {
				font-size: 50px;
			}
		}
	}

	.slick-next {
		@include right(15px);
		@include left(auto);

		@include mappy-bp(small) {
			@include right(25px);
		}
	}

	.slick-prev {
		@include left(15px);
		@include right(auto);

		@include mappy-bp(small) {
			@include left(25px);
		}
	}
}

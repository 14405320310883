.sectors-grid {

	.sector {
		@extend .full-height;
		padding: $padding-large-vertical;

		.bottom-link__anchor {
			@include padding-right($padding-large-vertical);
		}
	}
}

@mixin sharta($color: color, $padding: 25) {

	@if ($padding) {
		@include padding-left(#{$padding}px);
	}

	position: relative;

	&:before {
		@include left(0);
		position: absolute;
		top: 50%;
		bottom: 0;
		width: 25px;
		transform: translateY(-50%);
		content: '';
		display: inline-block;
		background: url('../img/icons/slash-#{$color}.svg') no-repeat center;
		background-size: contain;
	}
}

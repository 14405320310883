.logo-slash {
	position: relative;

	&__img {
		@include mappy-bp(max medium) {
			max-width: 90px;
		}
	}

	&__title {
		color: $brand-secondary;
		font-size: ms(5);
		font-family: $font-family-sans-serif;
		font-weight: normal;
		position: absolute;
		top: rem-calc(40);
		@include left(rem-calc(33));

		@include mappy-bp(max medium) {
			font-size: ms(3);
			top: rem-calc(30);
			@include left(rem-calc(25));
		}
	}
}

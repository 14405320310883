.hover-box {
	position: relative;
	background: #fff;
	box-shadow: 1px 1px 10px $gray-light;
	padding: vr(1);
	transition: transform 0.2s ease-in, box-shadow 0.2s ease-in;

	&:hover {
		cursor: pointer;
		transform: translateY(-3px);
		box-shadow: 5px 5px 20px $gray-light;
	}

	&__link {
		display: inline-block;
		color: $text-color !important;
	}
}

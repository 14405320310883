.image-box {

	&__anchor {
		display: inline-block;
		width: 100%;

		&:hover,
		&:visited,
		&:focus {
			text-decoration: none;
		}

		&:hover {
			.image-box__image {

				img {
					transform: translateY(-3px);
				}
			}
		}

		&:active {
			.image-box__image {

				img {
					transform: translateY(3px);
				}
			}
		}
	}

	&__image {
		margin-bottom: vr(1);
		padding-bottom: vr(1);
		border-bottom: 2px solid #fff;

		img {
			transition: transform 200ms ease-in-out;
		}
	}

	&__title {
		font-weight: 300;
		margin-bottom: 0;
	}

	&--meta {

		.image-box {

			&__image {
				border: 0;
				margin-bottom: 0;

				img {
					max-width: 64px;
				}
			}

			&__title {
				@include typi('h2');
				font-weight: bold;

				sub {
					@include typi('h6');
					bottom: 0;
				}

				span {
					display: inline-block;
					padding: 0 $padding-small-horizontal;
				}

				&__meta {
					font-family: $font-family-sans-serif;
					font-weight: 300;
					color: mix(#fff, $gray, 50%);
				}
			}
		}
	}
}

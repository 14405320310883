.media-box {
	overflow: auto;
	width: 100%;

	&__img {
		@include margin-right(rem-calc($padding-base-horizontal));
		margin-bottom: vr(1);

		@include mappy-bp(small) {
			@include float(left);
			max-width: 30%;
		}
	}

	&__content {
		overflow: hidden;

		p:first-of-type {
			margin-top: 0;
		}
	}

	&__title {
		margin-top: 0;
	}
}

$breakpoints: (
	xsmall: 580px,
	small: 768px,
	medium: 992px,
	large: 1200px,
	xlarge: 1600px
);

$typi-breakpoint: mappy-bp;

$typi: (
	base: (null: (16px, 1.6)),
	h1: (null: (ms(5), 1.2), small: (ms(6)), large: (ms(7))),
	h2: (null: (ms(4), 1.2), small: (ms(5)), large: (ms(6))),
	h3: (null: (ms(4), 1.2), small: (ms(4)), large: (ms(5))),
	h4: (null: (ms(3), 1.3), small: (ms(4)), large: (ms(4))),
	h5: (null: (ms(2), 1.4), small: (ms(3)), large: (ms(3))),
	h6: (null: (ms(1), 1.5)),
	h1Large: (null: (ms(6), 1.2), small: (ms(7)), large: (ms(9))),
	huge: (null: (ms(7), 1.2), small: (ms(9)), large: (ms(12))),
	small: (null: (ms(-1), 1.2)),
	xsmall: (null: (ms(-2), 1.2), small: (ms(-2)), large: (ms(-1)))
);

@include typi-init;

html,
body {
	min-height: 100%;
}

body {
	position: relative;
	overflow-x: hidden;
	background: #fff;
}

.page-wrapper {
	padding-top: rem-calc(101);
	float: left;
	width: 100%;

	@include mappy-bp(large) {
		padding-top: rem-calc(145);
	}
}

.no-free-will {
	@include mappy-bp(medium) {
		max-width: 70%;
		margin: auto;
	}
}
